<style lang="scss" scoped>
.errPage-container {
  width: 800px;
  height: 100%;
  margin: 0 auto;
  flex-direction: column;
  @include flexBox(center, flex-start);

  .pan-gif {
    margin: 0 auto;
    display: block;
  }

  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    color: #484848;
    margin: 20px 0;
  }

  .text-ginormous {
    margin-bottom: 20px;
  }

  .list-unstyled {
    font-size: 14px;

    li {
      padding-bottom: 5px;
    }

    a {
      color: #008489;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>

<template>
  <div class="errPage-container">
    <el-button icon="el-icon-arrow-left" type="custom_primary" size="medium"
      @click="$router.replace({ name: 'HOME' })">回到首页</el-button>
    <div>
      <h1 class="text-jumbo">哎呀!</h1>
      <h2 class="text-ginormous">你没有权限去该页面</h2>
    </div>
    <div>
      <img :src="errGif" width="313" height="428" alt="Girl has dropped her ice cream." />
    </div>
  </div>
</template>

<script>
import errGif from "@assets/images/401/401.gif";

export default {
  name: "Page401",
  data() {
    return {
      errGif: errGif + "?" + new Date(),
    };
  },
};
</script>
